<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <a v-if="member.cell && !member.bad_cell" :href="'tel:' + member.cell">
          <v-icon color="primary" size="16" class="mx-1" v-on="on">
            phone_iphone
          </v-icon>
        </a>
        <v-icon
          color="error"
          v-if="!member.cell || member.bad_cell"
          size="16"
          class="mx-1"
          v-on="on"
        >
          no_cell
        </v-icon>
      </template>
      <span v-if="!member.cell || member.bad_cell">No valid cell</span>
      <span v-else>{{ member.cell }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="!!member.email">
      <template v-slot:activator="{ on }">
        <a v-if="!isBad(member.email)" :href="'mailto:' + member.email">
          <v-icon color="primary" size="16" class="mx-1" v-on="on"
            >email</v-icon
          >
        </a>
        <v-icon
          v-if="isBad(member.email)"
          color="error"
          size="16"
          class="mx-1"
          v-on="on"
          >email</v-icon
        >
      </template>
      <span v-if="isBad(member.email)">No valid email</span>
      <span v-else>{{ member.email }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: "MemberEmailPhone",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
};
</script>
