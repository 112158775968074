<template>
  <v-flex xs12 class="text-xs-center">
    <v-flex :sm4="!isCompact" :sm6="isCompact" md>
      <!-- Search Box -->
      <v-text-field
        v-model="searchQuery"
        label="Filter by name, issue, or title"
        prepend-inner-icon="mdi-filter-variant"
        clearable
        outlined
        dense
      ></v-text-field>
    </v-flex>

    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
    <v-data-table
      v-else-if="staffs && staffs.length > 0"
      :headers="staffTableHeaders"
      :items="sortedStaffList"
      :items-per-page="pagination.itemsPerPage"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      :loading="loading"
      @update:options="handlePaginationUpdate"
      class="elevation-1"
      dense
      hide-default-footer
    >
      <template v-if="!isCompact" v-slot:item.actions="{ item }">
        <v-icon small color="primary" @click="editStaff(item)" v-if="editable">edit</v-icon>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="d-flex pt-1">
          <!-- Conditionally display star icon -->
          <v-icon
            v-if="
              item.roles.some(role => 
                (typeof role === 'object' ? role.role_name === 'Chief of Staff' : role === 'Chief of Staff')
              ) || 
              item.issues.some(issue => 
                (typeof issue === 'object' ? ['Health', 'Veterans affairs'].includes(issue.issue_name) : ['Health', 'Veterans affairs'].includes(issue))
              )
            "
            color="yellow darken-2"
            class="ml-2"
            small
          >
            mdi-star
          </v-icon>
          <span class="mr-1 no-line-break" style="white-space: nowrap;">
            <strong :class="{ 'bold-condition': item.roles.some(role => role.role_name === 'Chief of Staff') || item.issues.some(issue => issue.issue_name === 'Health' || issue.issue_name === 'Veterans affairs') }">
              {{ item.firstname }} {{ item.lastname }}
            </strong>
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a v-if="item.office_phone" :href="'tel:' + item.office_phone" v-on="on">
                <v-icon color="primary" size="16" class="mx-1">phone_iphone</v-icon>
              </a>
            </template>
            <span v-if="item.office_phone">{{ item.office_phone }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!!item.email_official">
            <template v-slot:activator="{ on }">
              <a :href="'mailto:' + item.email_official" v-on="on">
                <v-icon :color="isBad(item.email_official) ? 'error' : 'primary'" size="16" class="mx-1">email</v-icon>
              </a>
            </template>
            <span>{{ item.email_official }}</span>
          </v-tooltip>
          <v-chip v-if="!item.active" class="ma-2 custom-inactive-badge" x-small>INACTIVE</v-chip>
        </div>
        <div class="d-flex pb-1">
          <!-- Add Titles content under the name -->
          <span v-if="item.roles && item.roles.length > 0" v-html="formatTitles(item.roles)" class="text-smaller"></span>
        </div>
      </template>
      <template v-slot:item.issues="{ item }">
        <div>
          <span v-if="!item.showFullIssues">
            <span v-html="item.issuesText && isCompact ? item.issuesText.substring(0, 20): ''"></span>
            <span v-html="item.issuesText && !isCompact ? item.issuesText.substring(0, 180): ''"></span>
            <span v-if="item.issuesText && isCompact && item.issuesText.length > 20">...</span>
            <span v-if="item.issuesText && !isCompact && item.issuesText.length > 180">...</span>
          </span>
          <span v-else v-html="item.issuesText"></span>
          <v-btn
            x-small
            text
            v-if="item.issuesText && item.issuesText.length > 180"
            @click="item.showFullIssues = !item.showFullIssues"
          >
            {{ item.showFullIssues ? 'Show less' : 'Show more' }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
export default {
  name: 'staffList',
  props: {
    staffs: { type: Array, required: true },
    pagination: {
      type: Object,
      default: () => ({
        page: 1,
        itemsPerPage: 5, 
        totalItems: 0, 
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    isCompact: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    formattedStaffList() {
      return this.staffs.map((item) => {
        return {
          ...item,
          issuesText: this.formatIssues(item.issues),
          showFullIssues: false,
        }
      });
    },
    sortedStaffList() {
      const query = (this.searchQuery || '').toLowerCase();
      
      const filteredList = this.formattedStaffList.filter((item) => {
        const name = `${item.firstname} ${item.lastname}`.toLowerCase();
        const issues = (item.issuesText || '').toLowerCase();
        const roles = (item.roles || [])
          .map(role => (typeof role === 'object' && role.role_name ? role.role_name : role))
          .join(', ')
          .toLowerCase();
        
        return (
          name.includes(query) ||
          issues.includes(query) ||
          roles.includes(query)
        );
      });

      return filteredList.sort((a, b) => {
        const getRoleNames = (staff) =>
          (staff.roles || []).map(role => (typeof role === 'object' ? role.role_name : role));
        
        const isChiefA = getRoleNames(a).includes('Chief of Staff');
        const isChiefB = getRoleNames(b).includes('Chief of Staff');
        if (isChiefA && !isChiefB) return -1;
        if (!isChiefA && isChiefB) return 1;

        const hasPriorityIssueA = (a.issues || []).some(issue =>
          (typeof issue === 'object' && issue.issue_name
            ? ['Health', 'Veterans affairs'].includes(issue.issue_name)
            : ['Health', 'Veterans affairs'].includes(issue)
          )
        );
        const hasPriorityIssueB = (b.issues || []).some(issue =>
          (typeof issue === 'object' && issue.issue_name
            ? ['Health', 'Veterans affairs'].includes(issue.issue_name)
            : ['Health', 'Veterans affairs'].includes(issue)
          )
        );

        if (hasPriorityIssueA && !hasPriorityIssueB) return -1;
        if (!hasPriorityIssueA && hasPriorityIssueB) return 1;

        const issueLengthA = (a.issuesText || '').length;
        const issueLengthB = (b.issuesText || '').length;
        if (issueLengthA !== issueLengthB) {
          return issueLengthB - issueLengthA;
        }

        return a.firstname.localeCompare(b.firstname);
      });
    },
    staffTableHeaders() {
      let tableHeaders = []

      if (!this.isCompact) {
        tableHeaders.push({ text: '', value: 'actions', align: 'left', sortable: false })
      }

      tableHeaders.push(
        { text: 'Name', value: 'name' },
        { text: 'Issues', value: 'issues' },
      )
      
      return tableHeaders;
    }
  },
  methods: {
    formatIssues(issues) {
      if (!issues || !Array.isArray(issues)) return '';
      return issues
        .map((issue) => {
          const issueName = typeof issue === 'object' && issue.issue_name ? issue.issue_name : issue;
          return ['Health', 'Veterans affairs'].includes(issueName)
            ? `<strong>${issueName}</strong>`
            : issueName;
        })
        .join(', ');
    },
    handlePaginationUpdate({ page, itemsPerPage }) {
      if (page !== this.pagination.page || itemsPerPage !== this.pagination.itemsPerPage) {
        this.$emit('update-pagination', { page, itemsPerPage });
      }
    },
    formatTitles(roles) {
      if (!roles || !Array.isArray(roles)) return '';
      return roles
        .filter(role => {
          const roleName = typeof role === 'object' && role.role_name ? role.role_name : role;
          return roleName !== "All";
        })
        .map(role => (typeof role === 'object' && role.role_name ? role.role_name : role))
        .join(", ");
    },
    editStaff(item) {
      this.$emit('edit-staff', item);
    },
  },
};
</script>

<style>
.text-smaller {
  font-size: 0.85em;
  color: gray;    
}
</style>